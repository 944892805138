import React, { useRef } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

export default function LineGraph(props) {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const data = {
        labels: labels,
        datasets: [{
            label: 'Traffic',
            data: props.data,
            fill: false,
            borderColor: '#2196f3',
            tension: 0.4,
            // pointRadius: 0,
        }]
    };

    const options = {
        elements:{
            point:{
                borderWidth: 0,
                radius: 10,
                backgroundColor: 'rgba(0,0,0,0)'
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                // align: "start",
            },
            title: {
                display: false,
                text: '',
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                }
            },
            y: {
                grid: {
                    borderDash: [2],
                    drawBorder: false,
                },
                ticks: {
                    stepSize: 10,
                    callback: function(value, index, ticks) {
                        return '$' + value;
                    },
                    fontColor: 'green'
                }
            }
        }
            
    };
    return (
        <>
            <Line options={options} data={data} />

        </>
    )
}
