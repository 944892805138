// import logo from './logo.svg';
import './App.css';
import Header from './component/header'
import Footer from './component/footer'
import Dashboard from './component/dashboard/index'
import Sidebar from './component/sidebar'
import { Helmet } from "react-helmet";
import Preloader from './component/preloader';

function App() {
  return (
    <>
      <div className="mdk-drawer-layout__content page-content" style={{ transform: "none" }}>
        <Preloader />
        <Header />
        <Dashboard />
        <Footer />
      </div>
      <Sidebar />

      <Helmet>
        <script src="/assets/vendor/jquery.min.js" type="text/javascript" />
        <script defer src="/assets/vendor/popper.min.js"></script>
        <script defer src="/assets/vendor/bootstrap.min.js"></script>
        <script defer src="/assets/vendor/perfect-scrollbar.min.js"></script>
        <script defer src="/assets/vendor/dom-factory.js"></script>
        <script defer src="/assets/vendor/material-design-kit.js"></script>
        <script defer src="/assets/js/app.js"></script>
        <script defer src="/assets/js/hljs.js"></script>
        <script defer src="/assets/js/settings.js"></script>
        <script defer src="/assets/js/app.js"></script>
        <script defer src="/assets/js/sidebar.js"></script>
        <script defer src="/assets/js/toggle-check-all.js"></script>
        <script defer src="/assets/js/check-selected-row.js"></script>
      </Helmet>
    </>


  );
}

export default App;
