import React, {useEffect} from 'react'


function Sidebar() {

  
  useEffect(()=>{
    // const script = document.createElement("script");
    // script.src = "/assets/js/sidebar.js";
    // script.src = "/assets/js/dom-factory.js.js";
    // script.async = true;
    // document.head.appendChild(script);
  })


  return (
    <div className="mdk-drawer js-mdk-drawer"
    id="default-drawer">
   <div className="mdk-drawer__content">
       <div className="sidebar sidebar-dark sidebar-left"
            data-perfect-scrollbar>

          
          
           <a href="compact-/#"
              className="navbar-toggler navbar-toggler-right navbar-toggler-custom d-flex align-items-center justify-content-center position-absolute right-0 top-0"
              data-toggle="tooltip"
              data-title="Switch to Compact Vertical Layout"
              data-placement="right"
              data-boundary="window">
               <span className="material-icons">sync_alt</span>
           </a>

           <a href="/#"
              className="sidebar-brand ">
               <img className="sidebar-brand-icon"
                    src="assets/images/logo/accent-teal-100@2x.png"
                    alt="Huma"/>
               <span>Huma</span>
           </a>

           <div className="sidebar-account mx-16pt mb-16pt dropdown">
               <a href="/#"
                  className="nav-link d-flex align-items-center dropdown-toggle"
                  data-toggle="dropdown"
                  data-caret="false">
                   <img width="32"
                        height="32"
                        className="rounded-circle mr-8pt"
                        src="assets/images/people/50/guy-3.jpg"
                        alt="account" />
                   <span className="flex d-flex flex-column mr-8pt">
                       <span className="text-black-100">Laza Bogdan</span>
                       <small className="text-black-50">Administrator</small>
                   </span>
                   <i className="material-icons text-black-20 icon-16pt">keyboard_arrow_down</i>
               </a>
               <div className="dropdown-menu dropdown-menu-full dropdown-menu-caret-center">
                   <div className="dropdown-header"><strong>Account</strong></div>
                   <a className="dropdown-item"
                      href="/#">Edit Account</a>
                   <a className="dropdown-item"
                      href="/#">Billing</a>
                   <a className="dropdown-item"
                      href="/#">Payments</a>
                   <a className="dropdown-item"
                      href="/#">Logout</a>
                   <div className="dropdown-divider"></div>
                   <div className="dropdown-header"><strong>Select company</strong></div>
                   <a href="/#"
                      className="dropdown-item active d-flex align-items-center">

                       <div className="avatar avatar-sm mr-8pt">

                           <span className="avatar-title rounded bg-primary">FM</span>

                       </div>

                       <small className="ml-4pt flex">
                           <span className="d-flex flex-column">
                               <strong className="text-black-100">FrontendMatter Inc.</strong>
                               <span className="text-black-50">Administrator</span>
                           </span>
                       </small>
                   </a>
                   <a href="/#"
                      className="dropdown-item d-flex align-items-center">

                       <div className="avatar avatar-sm mr-8pt">

                           <span className="avatar-title rounded bg-accent">HH</span>

                       </div>

                       <small className="ml-4pt flex">
                           <span className="d-flex flex-column">
                               <strong className="text-black-100">HumaHuma Inc.</strong>
                               <span className="text-black-50">Publisher</span>
                           </span>
                       </small>
                   </a>
               </div>
           </div>

           <form action="/#"
                 className="search-form flex-shrink-0 search-form--black sidebar-m-b sidebar-p-l mx-16pt pr-0">
               <input type="text"
                      className="form-control pl-0"
                      placeholder="Search"/>
               <button className="btn"
                       type="submit"><i className="material-icons">search</i></button>
           </form>

           <div className="sidebar-heading">Overview</div>
           <ul className="sidebar-menu">
               <li className="sidebar-menu-item active">
                   <a className="sidebar-menu-button"
                      href="/#">
                       <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">insert_chart_outlined</span>
                       <span className="sidebar-menu-text">Dashboard</span>
                   </a>
               </li>
               <li className="sidebar-menu-item">
                   <a className="sidebar-menu-button"
                      data-toggle="collapse"
                      href="#dashboards_menu">
                       <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">link</span>
                       Shortcuts
                       <span className="ml-auto sidebar-menu-toggle-icon"></span>
                   </a>
                   <ul className="sidebar-submenu collapse sm-indent"
                       id="dashboards_menu">
                       <li className="sidebar-menu-item active">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Analytics Dashboard</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Analytics 2 Dashboard</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Projects Dashboard</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Tasks Dashboard</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Staff Dashboard</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Shop Dashboard</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">ERP Dashboard</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">CRM Dashboard</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">HR Dashboard</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">CMS Dashboard</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button disabled"
                              href="/#">
                               <span className="sidebar-menu-text">Card Metrics</span>
                           </a>
                       </li>
                   </ul>
               </li>
           </ul>

           <div className="sidebar-heading">Applications</div>
           <ul className="sidebar-menu">
               <li className="sidebar-menu-item">
                   <a className="sidebar-menu-button js-sidebar-collapse"
                      data-toggle="collapse"
                      href="#enterprise_menu">
                       <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">donut_large</span>
                       Enterprise
                       <span className="ml-auto sidebar-menu-toggle-icon"></span>
                   </a>
                   <ul className="sidebar-submenu collapse sm-indent"
                       id="enterprise_menu">
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">ERP Dashboard</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">CRM Dashboard</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">HR Dashboard</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Employees</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Staff</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Leaves</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button disabled"
                              href="/#">
                               <span className="sidebar-menu-text">Departments</span>
                           </a>
                       </li>
                    
                    
                   </ul>
               </li>
               <li className="sidebar-menu-item">
                   <a className="sidebar-menu-button"
                      data-toggle="collapse"
                      href="#productivity_menu">
                       <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">access_time</span>
                       Productivity
                       <span className="ml-auto sidebar-menu-toggle-icon"></span>
                   </a>
                   <ul className="sidebar-submenu collapse sm-indent"
                       id="productivity_menu">
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Projects</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Tasks Board</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Tasks List</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button disabled"
                              href="/#">
                               <span className="sidebar-menu-text">Kanban</span>
                           </a>
                       </li>
                   
                   
                   </ul>
               </li>
               <li className="sidebar-menu-item">
                   <a className="sidebar-menu-button"
                      data-toggle="collapse"
                      href="#ecommerce_menu">
                       <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">shopping_cart</span>
                       eCommerce
                       <span className="ml-auto sidebar-menu-toggle-icon"></span>
                   </a>
                   <ul className="sidebar-submenu collapse sm-indent"
                       id="ecommerce_menu">
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Shop Dashboard</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button disabled"
                              href="/#">
                               <span className="sidebar-menu-text">Edit Product</span>
                           </a>
                       </li>
                   </ul>
               </li>
               <li className="sidebar-menu-item">
                   <a className="sidebar-menu-button"
                      data-toggle="collapse"
                      href="#messaging_menu">
                       <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">message</span>
                       Messaging
                       <span className="sidebar-menu-badge badge badge-accent badge-notifications ml-auto">2</span>
                       <span className="sidebar-menu-toggle-icon"></span>
                   </a>
                   <ul className="sidebar-submenu collapse sm-indent"
                       id="messaging_menu">
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Messages</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Email</span>
                           </a>
                       </li>
                   </ul>
               </li>
             
             
               <li className="sidebar-menu-item">
                   <a className="sidebar-menu-button"
                      data-toggle="collapse"
                      href="#cms_menu">
                       <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">content_copy</span>
                       CMS
                       <span className="ml-auto sidebar-menu-toggle-icon"></span>
                   </a>
                   <ul className="sidebar-submenu collapse sm-indent"
                       id="cms_menu">
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">CMS Dashboard</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Posts</span>
                           </a>
                       </li>
                   </ul>
               </li>
               <li className="sidebar-menu-item">
                   <a className="sidebar-menu-button"
                      data-toggle="collapse"
                      href="#account_menu">
                       <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">account_box</span>
                       Account
                       <span className="ml-auto sidebar-menu-toggle-icon"></span>
                   </a>
                   <ul className="sidebar-submenu collapse sm-indent"
                       id="account_menu">
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Pricing</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Login</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Signup</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Payment</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Reset Password</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Change Password</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Edit Account</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Profile &amp; Privacy</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Email Notifications</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Account Password</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Subscription</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Upgrade Account</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Payment Information</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Payment History</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Invoice</span>
                           </a>
                       </li>
                   </ul>
               </li>
               <li className="sidebar-menu-item">
                   <a className="sidebar-menu-button"
                      data-toggle="collapse"
                      href="#community_menu">
                       <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">people_outline</span>
                       Community
                       <span className="ml-auto sidebar-menu-toggle-icon"></span>
                   </a>
                   <ul className="sidebar-submenu collapse sm-indent"
                       id="community_menu">
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">FAQ</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Discussions</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Discussion Details</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Ask Question</span>
                           </a>
                       </li>
                   </ul>
               </li>
           </ul>

           <div className="sidebar-heading">UI</div>
           <ul className="sidebar-menu">
               <li className="sidebar-menu-item">
                   <a className="sidebar-menu-button"
                      data-toggle="collapse"
                      href="#components_menu">
                       <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">tune</span>
                       Components
                       <span className="ml-auto sidebar-menu-toggle-icon"></span>
                   </a>
                   <ul className="sidebar-submenu collapse sm-indent"
                       id="components_menu">
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Buttons</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Avatars</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Forms</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Loaders</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Tables</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Cards</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Icons</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Tabs</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Alerts</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Badges</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Progress</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Pagination</span>
                           </a>
                       </li>
                     
                     
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button disabled"
                              href="/#">
                               <span className="sidebar-menu-text">Disabled</span>
                           </a>
                       </li>
                   </ul>
               </li>
               <li className="sidebar-menu-item">
                   <a className="sidebar-menu-button"
                      data-toggle="collapse"
                      href="#plugins_menu">
                       <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">folder</span>
                       Plugins
                       <span className="ml-auto sidebar-menu-toggle-icon"></span>
                   </a>
                   <ul className="sidebar-submenu collapse sm-indent"
                       id="plugins_menu">
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Charts</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Flatpickr</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Date Range Picker</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Dragula</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Dropzone</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Range Sliders</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Quill</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Select2</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Nestable</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Fancy Tree</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Vector Maps</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Sweet Alert</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">Toastr</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button disabled"
                              href="/#">
                               <span className="sidebar-menu-text">Disabled</span>
                           </a>
                       </li>
                   </ul>
               </li>
               <li className="sidebar-menu-item">
                   <a className="sidebar-menu-button"
                      data-toggle="collapse"
                      href="#layouts_menu">
                       <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">view_compact</span>
                       Layouts
                       <span className="ml-auto sidebar-menu-toggle-icon"></span>
                   </a>
                   <ul className="sidebar-submenu collapse sm-indent"
                       id="layouts_menu">
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="compact-/#">
                               <span className="sidebar-menu-text">Compact</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="mini-/#">
                               <span className="sidebar-menu-text">Mini</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item active">
                           <a className="sidebar-menu-button"
                              href="/#">
                               <span className="sidebar-menu-text">App</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="boxed-/#">
                               <span className="sidebar-menu-text">Boxed</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="sticky-/#">
                               <span className="sidebar-menu-text">Sticky</span>
                           </a>
                       </li>
                       <li className="sidebar-menu-item">
                           <a className="sidebar-menu-button"
                              href="fixed-/#">
                               <span className="sidebar-menu-text">Fixed</span>
                           </a>
                       </li>
                   </ul>
               </li>
           </ul>

       </div>
   </div>
</div>
  )
}

export default Sidebar