import React from 'react'

function footer() {
  return (

    <div className="js-fix-footer footer border-top-2">
      <div className="container-fluid page__container page-section d-flex flex-column">
        <p className="text-70 brand mb-24pt">
          <img className="brand-icon" src="assets/images/logo/black-70@2x.png" width={30} alt="Huma" /> Huma
        </p>
        <p className="measure-lead-max text-muted mb-0 small">Huma is a beautifully crafted user interface for modern Business Admin Web Applications, with examples for many pages needed for Customer Relationship Management, Enterprise Resource Planning, Human Resources, Content Management System, Project Management, Tasks, eCommerce, Messaging and Account Management.</p>
      </div>
      <div className="pb-16pt pb-lg-24pt">
        <div className="container-fluid page__container">
          <div className="bg-dark rounded page-section py-lg-32pt px-16pt px-lg-24pt">
            <div className="row">
              <div className="col-md-2 col-sm-4 mb-24pt mb-md-0">
                <p className="text-white-70 mb-8pt"><strong>Follow us</strong></p>
                <nav className="nav nav-links nav--flush">
                  <a href="/#" className="nav-link mr-8pt"><img src="assets/images/icon/footer/facebook-square@2x.png" width={24} height={24} alt="Facebook" /></a>
                  <a href="/#" className="nav-link mr-8pt"><img src="assets/images/icon/footer/twitter-square@2x.png" width={24} height={24} alt="Twitter" /></a>
                  <a href="/#" className="nav-link mr-8pt"><img src="assets/images/icon/footer/vimeo-square@2x.png" width={24} height={24} alt="Vimeo" /></a>
                  {/* <a href="/#" className="nav-link"><img src="assets/images/icon/footer/youtube-square@2x.png" width="24" height="24" alt="YouTube"></a> */}
                </nav>
              </div>
              <div className="col-md-6 col-sm-4 mb-24pt mb-md-0 d-flex align-items-center">
                <a href className="btn btn-outline-white">English <span className="icon--right material-icons">arrow_drop_down</span></a>
              </div>
              <div className="col-md-4 text-md-right">
                <p className="mb-8pt d-flex align-items-md-center justify-content-md-end">
                  <a href className="text-white-70 text-underline mr-16pt">Terms</a>
                  <a href className="text-white-70 text-underline">Privacy policy</a>
                </p>
                <p className="text-white-50 small mb-0">Copyright 2019 © All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  )
}

export default footer