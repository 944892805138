import React, { useEffect, useState } from "react";
import CardLineGraph from "./Graph/CardLineGraph";
import Dchart from "./Graph/Dchart";
import LineGraph from "./Graph/LineGraph";
import VectorMap from "./Graph/VectorMap";

function Dashboard() {

  const [doughnut, setDoughnut] = useState();
  const [trafficLineData, setTrafficLineData] = useState()
  const [dStatus, setDStatus] = useState(false);
  const [defaultLineGraph, setDefaultLineGraph] = useState(false);
  const [vectorMapData, setVectorMapData] = useState(false);
  const [changeRegion,setChangeRegion ] = useState(false);
  const [focus,setFocus] = useState(null);

  if (dStatus === false) {
    setDStatus(true);
    const traficData = [25, 25, 15];
    setDoughnut(<Dchart data={traficData} />)
  }

  const updateDoghnutChart = (param) => (e) => {
    e.preventDefault();
    setDStatus(true);
    //mark tab active
    document.querySelectorAll('.card-header-tabs-basic a')
      .forEach(function (el) {
        el.classList.remove('active');
      })
    e.target.classList.add('active')
    //update chart
    setDoughnut(<Dchart data={param} />)
  }

  /** Line Graph Traffic Data */
  if (defaultLineGraph === false) {
    setDefaultLineGraph(true);
    const LineTraficData = [10, 2, 5, 15, 10, 12, 15, 25, 22, 30, 25, 40];
    setTrafficLineData(<LineGraph data={LineTraficData} />)
  }

  const updateLineGraphTrafficChart = (param) => (e) => {
    e.preventDefault();
    setDefaultLineGraph(true);
    //mark tab active
    document.querySelectorAll('.lineGraph a')
      .forEach(function (el) {
        el.classList.remove('active');
      })
    e.target.classList.add('active')
    e.target.parentNode.classList.add("active");
    //update chart
    setTrafficLineData(<LineGraph data={param} />)
  }

  const focusRegion = (region) => (e) =>{
    e.preventDefault();
    setChangeRegion(true)
    setFocus(region)
  }

  useEffect(() => {
    if(changeRegion === true && focus !== null){
      setChangeRegion(false)
      setVectorMapData(<VectorMap region={focus} />)
    }

    if(vectorMapData === false && changeRegion === false && focus === null){
      setVectorMapData(<VectorMap />)
    }
  },[changeRegion, focus, vectorMapData])

  return (
    <>
      <div className="border-bottom-2 py-32pt position-relative z-1">
        <div className="container-fluid page__container d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
          <div className="flex d-flex flex-column flex-sm-row align-items-center mb-24pt mb-md-0">
            <div className="mb-24pt mb-sm-0 mr-sm-24pt">
              <h2 className="mb-0">Dashboard</h2>
              <ol className="breadcrumb p-0 m-0">
                <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                <li className="breadcrumb-item active">
                  Dashboard
                </li>
              </ol>
            </div>
            <div className="dropdown">
              <a href className="border rounded d-flex align-items-center p-16pt" data-toggle="dropdown" data-caret="false">
                <div className="avatar avatar-sm mr-8pt">
                  <span className="avatar-title rounded bg-primary">FM</span>
                </div>
                <small className="ml-4pt flex">
                  <span className="d-flex align-items-center">
                    <span className="flex d-flex flex-column">
                      <strong className="text-100">FrontendMatter Inc.</strong>
                      <span className="text-50">Select company</span>
                    </span>
                    <i className="material-icons icon-16pt text-50 ml-4pt">arrow_drop_down</i>
                  </span>
                </small>
              </a>
              <div className="dropdown-menu w-100">
                <div className="dropdown-header"><strong>Select company</strong></div>
                <a href className="dropdown-item active d-flex align-items-center">
                  <div className="avatar avatar-sm mr-8pt">
                    <span className="avatar-title rounded bg-primary">FM</span>
                  </div>
                  <small className="ml-4pt flex">
                    <span className="d-flex flex-column">
                      <strong className="text-black-100">FrontendMatter Inc.</strong>
                      <span className="text-black-50">Administrator</span>
                    </span>
                  </small>
                </a>
                <a href className="dropdown-item d-flex align-items-center">
                  <div className="avatar avatar-sm mr-8pt">
                    <span className="avatar-title rounded bg-accent">HH</span>
                  </div>
                  <small className="ml-4pt flex">
                    <span className="d-flex flex-column">
                      <strong className="text-black-100">HumaHuma Inc.</strong>
                      <span className="text-black-50">Publisher</span>
                    </span>
                  </small>
                </a>
              </div>
            </div>
          </div>
          <div className="row" role="tablist">
            <div className="col-auto d-flex flex-column">
              <h6 className="m-0">$12.3k</h6>
              <p className="text-50 mb-0 d-flex align-items-center">
                Earnings
                <i className="material-icons text-accent ml-4pt icon-16pt">keyboard_arrow_up</i>
              </p>
            </div>
            <div className="col-auto border-left">
              <h6 className="m-0">264</h6>
              <p className="text-50 mb-0 d-flex align-items-center">
                Sales
                <i className="material-icons text-accent ml-4pt icon-16pt">keyboard_arrow_up</i>
              </p>
            </div>
            <div className="col-auto border-left">
              <a href className="btn btn-accent">New Report</a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid page__container">
        <div className="page-section">
          <div className="alert alert-soft-warning mb-lg-32pt">
            <div className="d-flex flex-wrap align-items-start">
              <div className="mr-8pt">
                <i className="material-icons">access_time</i>
              </div>
              <div className="flex" style={{ minWidth: 180 }}>
                <small className="text-100">
                  <strong>Analytics Service Issues.</strong><br />
                  <span>You may experience some issues with the Analytics API. Stay up to date by following our status page.</span>
                </small>
              </div>
            </div>
          </div>
          <div className="page-separator">
            <div className="page-separator__text">Overview</div>
          </div>
          <div className="row card-group-row mb-lg-8pt">
            <div className="col-xl-3 col-md-6 card-group-row__col">
              <div className="card card-group-row__card">
                <div className="card-body d-flex flex-column align-items-center">
                  <i className="material-icons icon-32pt text-20 mb-4pt">access_time</i>
                  <div className="d-flex align-items-center">
                    <div className="h2 mb-0 mr-3">3.6k</div>
                    <div className="flex">
                      <p className="mb-0"><strong>Visits</strong></p>
                      <p className="text-50 mb-0 mt-n1 d-flex align-items-center">
                        31.5%
                        <i className="material-icons text-accent ml-4pt icon-16pt">keyboard_arrow_up</i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 card-group-row__col">
              <div className="card card-group-row__card">
                <div className="card-body d-flex flex-column align-items-center">
                  <i className="material-icons icon-32pt text-20 mb-4pt">attach_money</i>
                  <div className="d-flex align-items-center">
                    <div className="h2 mb-0 mr-3">$12.3k</div>
                    <div className="flex">
                      <p className="mb-0"><strong>Sales</strong></p>
                      <p className="text-50 mb-0 mt-n1 d-flex align-items-center">
                        51.5%
                        <i className="material-icons text-accent ml-4pt icon-16pt">keyboard_arrow_up</i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 card-group-row__col">
              <div className="card card-group-row__card card-body" style={{ position: 'relative', paddingBottom: 'calc(80px - 1.25rem)', overflow: 'hidden', zIndex: 0 }}>
                <div className="d-flex align-items-center">
                  <div className="h2 mb-0 mr-3">$8.3k</div>
                  <div className="flex">
                    <p className="mb-0"><strong>Products</strong></p>
                    <p className="text-50 mb-0 mt-n1 d-flex align-items-center">
                      31.5%
                      <i className="material-icons text-accent ml-4pt icon-16pt">keyboard_arrow_up</i>
                    </p>
                  </div>
                </div>
                <div className="chart" style={{ height: 80, position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                    <CardLineGraph data={[400,200,450,460,220,380,800]} />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 card-group-row__col">
              <div className="card card-group-row__card card-body" style={{ position: 'relative', paddingBottom: 'calc(80px - 1.25rem)', overflow: 'hidden', zIndex: 0 }}>
                <div className="d-flex align-items-center">
                  <div className="h2 mb-0 mr-3">$15.0k</div>
                  <div className="flex">
                    <p className="mb-0"><strong>Courses</strong></p>
                    <p className="text-50 mb-0 mt-n1 d-flex align-items-center">
                      31.5%
                      <i className="material-icons text-accent ml-4pt icon-16pt">keyboard_arrow_down</i>
                    </p>
                  </div>
                </div>
                <div className="chart" style={{ height: 80, position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                  <CardLineGraph data={[400,200,450,460,220,380,800]} lineColor={'#00bcc2'} />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-lg-8pt">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-header card-header-tabs-basic nav justify-content-center" style={{ zIndex: '9999999999999' }} role="tablist">

                  <a href="/#" className="tab active" onClick={updateDoghnutChart([25, 25, 15])}>
                    Traffic
                  </a>
                  <a href="/#" className="tab" onClick={updateDoghnutChart([25, 15, 25])}>
                    Purchases
                  </a>
                  <a href="/#" className="tab" onClick={updateDoghnutChart([53, 17, 25])}>
                    Quotes
                  </a>
                </div>
                <div className="card-body d-flex align-items-center justify-content-center" style={{ height: 220 }}>
                  <div className="row">
                    <div className="col-12">
                      <div className="position-relative" style={{ height: 'calc(335px - 1rem * 2)' }}>

                        {doughnut}

                        <div className="text-center fullbleed d-flex align-items-center justify-content-center flex-column z-0 ml-140">
                          <h3 className="mb-0">38%</h3>
                          <small className="text-uppercase">United States</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="card dashboard-area-tabs" id="dashboard-area-tabs">
                <div className="card-header p-0 nav">
                  <div className="row no-gutters flex lineGraph" role="tablist">
                    <a href="/#" onClick={updateLineGraphTrafficChart([10, 2, 5, 15, 10, 12, 15, 25, 22, 30, 25, 40])} className="dashboard-area-tabs__tab card-body text-center active">
                      <span className="font-weight-bold">Sessions</span>
                      <span className="h2 mb-0 mt-n1">18.3k</span>
                    </a>

                    <a href="/#" onClick={updateLineGraphTrafficChart([7, 35, 12, 27, 34, 17, 19, 30, 28, 32, 24, 39])} className="dashboard-area-tabs__tab card-body text-center">
                      <span className="font-weight-bold">Orders</span>
                      <span className="h2 mb-0 mt-n1">$8.9k</span>
                    </a>

                  </div>
                </div>
                <div className="card-body text-muted">
                  <div className="chart" style={{ height: 'calc(280px - 1rem * 2)' }}>

                    {trafficLineData}

                  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card">
                <div className="card-header p-0 nav">
                  <div className="row no-gutters flex" role="tablist">
                    <div className="col-auto">
                      <div className="p-card-header">
                        <p className="mb-0"><strong>Revenue</strong></p>
                        <p className="text-50 mb-0 mt-n1 d-flex align-items-center">
                          $203k
                          <i className="material-icons text-accent ml-4pt icon-16pt">keyboard_arrow_up</i>
                        </p>
                      </div>
                    </div>
                    <div className="col-auto border-left">
                      <div className="p-card-header">
                        <p className="mb-0"><strong>Employees</strong></p>
                        <p className="text-50 mb-0 mt-n1 d-flex align-items-center">
                          264
                          <i className="material-icons text-accent ml-4pt icon-16pt">keyboard_arrow_up</i>
                        </p>
                      </div>
                    </div>
                    <div className="col-auto ml-sm-auto">
                      <div className="p-card-header"><a href="/#"><i className="material-icons text-50">more_horiz</i></a></div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="mapdiv">
                    { vectorMapData }
                  </div>

                  <div className="alert alert-soft-warning">
                    <div className="d-flex flex-wrap align-items-start">
                      <div className="mr-8pt">
                        <i className="material-icons">access_time</i>
                      </div>
                      <div className="flex" style={{ minWidth: 180 }}>
                        <small className="text-100">
                          <strong>Congrats to the New York Team</strong> For the excellent revenue so far!
                        </small>
                      </div>
                    </div>
                  </div>
                  <ul className="list-unstyled dashboard-location-tabs nav flex-column m-0" role="tablist">
                    <li onClick={focusRegion('CN') } data-toggle="vector-map-focus" data-animate="true">
                      <div className="dashboard-location-tabs__tab active" data-toggle="tab" role="tab" aria-selected="true">
                        <div><strong>China</strong></div>
                        <div className="d-flex align-items-center">
                          <div className="flex mr-2">
                            <div className="progress" style={{ height: 4 }}>
                              <div className="progress-bar" role="progressbar" style={{ width: '72%' }} aria-valuenow={72} aria-valuemin={0} aria-valuemax={100} />
                            </div>
                          </div>
                          <div>72k</div>
                        </div>
                      </div>
                    </li>
                    <li onClick={focusRegion('GB') } data-toggle="vector-map-focus" data-target="#vector-map-revenue" data-focus="it" data-animate="true">
                      <div className="dashboard-location-tabs__tab" data-toggle="tab" role="tab" aria-selected="true">
                        <div><strong>United Kingdom</strong></div>
                        <div className="d-flex align-items-center">
                          <div className="flex mr-2">
                            <div className="progress" style={{ height: 4 }}>
                              <div className="progress-bar bg-primary" role="progressbar" style={{ width: '39%' }} aria-valuenow={39} aria-valuemin={0} aria-valuemax={100} />
                            </div>
                          </div>
                          <div>39k</div>
                        </div>
                      </div>
                    </li>
                    <li onClick={focusRegion('EG') } data-toggle="vector-map-focus" data-target="#vector-map-revenue" data-focus="au" data-animate="true">
                      <div className="dashboard-location-tabs__tab" data-toggle="tab" role="tab" aria-selected="true">
                        <div><strong>Egypt</strong></div>
                        <div className="d-flex align-items-center">
                          <div className="flex mr-2">
                            <div className="progress" style={{ height: 4 }}>
                              <div className="progress-bar bg-primary" role="progressbar" style={{ width: '25%' }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                            </div>
                          </div>
                          <div>25k</div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-lg-8pt">
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body d-flex align-items-center">
                  <div className="h2 mb-0 mr-3">$12.9k</div>
                  <div className="flex">
                    <p className="mb-0"><strong>Target</strong></p>
                    <p className="text-50 mb-0 mt-n1 d-flex align-items-center">
                      31.5%
                      <i className="material-icons text-accent ml-4pt icon-16pt">keyboard_arrow_up</i>
                    </p>
                  </div>
                  <i className="material-icons icon-48pt text-20 ml-2">access_time</i>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body d-flex align-items-center">
                  <div className="h2 mb-0 mr-3">$3.6k</div>
                  <div className="flex">
                    <p className="mb-0"><strong>Earnings</strong></p>
                    <p className="text-50 mb-0 mt-n1 d-flex align-items-center">
                      51.5%
                      <i className="material-icons text-accent ml-4pt icon-16pt">keyboard_arrow_up</i>
                    </p>
                  </div>
                  <i className="material-icons icon-48pt text-20 ml-2">attach_money</i>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body d-flex align-items-center">
                  <div className="h2 mb-0 mr-3">8.3k</div>
                  <div className="flex">
                    <p className="mb-0"><strong>Visits</strong></p>
                    <p className="text-50 mb-0 mt-n1 d-flex align-items-center">
                      3.5%
                      <i className="material-icons text-accent ml-4pt icon-16pt">keyboard_arrow_down</i>
                    </p>
                  </div>
                  <i className="material-icons icon-48pt text-20 ml-2">person_outline</i>
                </div>
              </div>
            </div>
          </div>
          <div className="page-separator">
            <div className="page-separator__text">Current Users</div>
          </div>
          <div className="card mb-lg-32pt">
            <div className="table-responsive" data-toggle="lists" data-lists-sort-by="js-lists-values-employee-name" data-lists-values="[js-lists-values-employee-name, js-lists-values-employer-name, js-lists-values-projects, js-lists-values-activity, js-lists-values-earnings]">
              <div className="card-header">
                <form className="form-inline">
                  <label className="mr-sm-2 form-label" htmlFor="inlineFormFilterBy">Filter by:</label>
                  <input type="text" className="form-control search mb-2 mr-sm-2 mb-sm-0" id="inlineFormFilterBy" placeholder="Search ..." />
                  <label className="sr-only" htmlFor="inlineFormRole">Role</label>
                  <select id="inlineFormRole" className="custom-select mb-2 mr-sm-2 mb-sm-0">
                    <option value="All Roles">All Roles</option>
                  </select>
                  <div className="ml-auto mb-2 mb-sm-0 custom-control-inline mr-0">
                    <label className="form-label mb-0" htmlFor="active">Active</label>
                    <div className="custom-control custom-checkbox-toggle ml-8pt">
                      <input defaultChecked type="checkbox" id="active" className="custom-control-input" />
                      <label className="custom-control-label" htmlFor="active">Active</label>
                    </div>
                  </div>
                  {/* <div className="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0">
  <input type="checkbox" className="custom-control-input" id="inlineFormPurchase">
  <label className="custom-control-label" for="inlineFormPurchase">Made a Purchase?</label>
</div> */}
                </form>
              </div>
              <table className="table mb-0 thead-border-top-0 table-nowrap">
                <thead>
                  <tr>
                    <th style={{ width: 18 }} className="pr-0">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input js-toggle-check-all" data-target="#staff" id="customCheckAllstaff" />
                        <label className="custom-control-label" htmlFor="customCheckAllstaff"><span className="text-hide">Toggle all</span></label>
                      </div>
                    </th>
                    <th>
                      <a href="/#" className="sort" data-sort="js-lists-values-employee-name">Employee</a>
                    </th>
                    <th style={{ width: 150 }}>
                      <a href="/#" className="sort" data-sort="js-lists-values-employer-name">Employer</a>
                    </th>
                    <th className="text-center" style={{ width: 48 }}>
                      <a href="/#" className="sort" data-sort="js-lists-values-projects">Projects</a>
                    </th>
                    <th style={{ width: 37 }}>Status</th>
                    <th style={{ width: 120 }}>
                      <a href="/#" className="sort" data-sort="js-lists-values-activity">Activity</a>
                    </th>
                    <th style={{ width: 51 }}>
                      <a href="/#" className="sort" data-sort="js-lists-values-earnings">Earnings</a>
                    </th>
                    <th style={{ width: 24 }} className="pl-0" />
                  </tr>
                </thead>
                <tbody className="list" id="staff">
                  <tr className="selected">
                    <td className="pr-0">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input js-check-selected-row" defaultChecked id="customCheck1_1" />
                        <label className="custom-control-label" htmlFor="customCheck1_1"><span className="text-hide">Check</span></label>
                      </div>
                    </td>
                    <td>
                      <div className="media flex-nowrap align-items-center" style={{ whiteSpace: 'nowrap' }}>
                        <div className="avatar avatar-32pt mr-8pt">
                          <img src="assets/images/people/110/guy-1.jpg" alt="Avatar" className="avatar-img rounded-circle" />
                        </div>
                        <div className="media-body">
                          <div className="d-flex flex-column">
                            <p className="mb-0"><strong className="js-lists-values-employee-name">Michael Smith</strong></p>
                            <small className="js-lists-values-employee-email text-50" />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <a href="/#" className="text-warning"><i className="material-icons mr-8pt">star</i></a>
                        <a href className="text-70"><span className="js-lists-values-employer-name">Black Ops</span></a>
                      </div>
                    </td>
                    <td className="text-center js-lists-values-projects small">12</td>
                    <td>
                      <a href className="chip chip-outline-secondary">Admin</a>
                    </td>
                    <td className="text-50 js-lists-values-activity small">3 days ago</td>
                    <td className="js-lists-values-earnings small">$12,402</td>
                    <td className="text-right pl-0">
                      <a href className="text-50"><i className="material-icons">more_vert</i></a>
                    </td>
                  </tr>
                  <tr>
                    <td className="pr-0">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input js-check-selected-row" id="customCheck1_2" />
                        <label className="custom-control-label" htmlFor="customCheck1_2"><span className="text-hide">Check</span></label>
                      </div>
                    </td>
                    <td>
                      <div className="media flex-nowrap align-items-center" style={{ whiteSpace: 'nowrap' }}>
                        <div className="avatar avatar-32pt mr-8pt">
                          <span className="avatar-title rounded-circle">CS</span>
                        </div>
                        <div className="media-body">
                          <div className="d-flex flex-column">
                            <p className="mb-0"><strong className="js-lists-values-employee-name">Connie Smith</strong></p>
                            <small className="js-lists-values-employee-email text-50" />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <a href="/#" className="text-warning"><i className="material-icons mr-8pt">star</i></a>
                        <a href className="text-70"><span className="js-lists-values-employer-name">Backend Ltd</span></a>
                      </div>
                    </td>
                    <td className="text-center js-lists-values-projects small">42</td>
                    <td>
                      <a href className="chip chip-outline-secondary">User</a>
                    </td>
                    <td className="text-50 js-lists-values-activity small">1 week ago</td>
                    <td className="js-lists-values-earnings small">$1,943</td>
                    <td className="text-right pl-0">
                      <a href className="text-50"><i className="material-icons">more_vert</i></a>
                    </td>
                  </tr>
                  <tr>
                    <td className="pr-0">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input js-check-selected-row" id="customCheck1_3" />
                        <label className="custom-control-label" htmlFor="customCheck1_3"><span className="text-hide">Check</span></label>
                      </div>
                    </td>
                    <td>
                      <div className="media flex-nowrap align-items-center" style={{ whiteSpace: 'nowrap' }}>
                        <div className="avatar avatar-32pt mr-8pt">
                          <img src="assets/images/people/110/guy-2.jpg" alt="Avatar" className="avatar-img rounded-circle" />
                        </div>
                        <div className="media-body">
                          <div className="d-flex flex-column">
                            <p className="mb-0"><strong className="js-lists-values-employee-name">John Connor</strong></p>
                            <small className="js-lists-values-employee-email text-50" />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <a href="/#" className="text-warning"><i className="material-icons mr-8pt">star</i></a>
                        <a href className="text-70"><span className="js-lists-values-employer-name">Frontted</span></a>
                      </div>
                    </td>
                    <td className="text-center js-lists-values-projects small">31</td>
                    <td>
                      <a href className="chip chip-outline-secondary">Manager</a>
                    </td>
                    <td className="text-50 js-lists-values-activity small">2 weeks ago</td>
                    <td className="js-lists-values-earnings small">$1,401</td>
                    <td className="text-right pl-0">
                      <a href className="text-50"><i className="material-icons">more_vert</i></a>
                    </td>
                  </tr>
                  <tr className="selected">
                    <td className="pr-0">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input js-check-selected-row" defaultChecked id="customCheck1_4" />
                        <label className="custom-control-label" htmlFor="customCheck1_4"><span className="text-hide">Check</span></label>
                      </div>
                    </td>
                    <td>
                      <div className="media flex-nowrap align-items-center" style={{ whiteSpace: 'nowrap' }}>
                        <div className="avatar avatar-32pt mr-8pt">
                          <span className="avatar-title rounded-circle">LB</span>
                        </div>
                        <div className="media-body">
                          <div className="d-flex flex-column">
                            <p className="mb-0"><strong className="js-lists-values-employee-name">Laza Bogdan</strong></p>
                            <small className="js-lists-values-employee-email text-50" />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <a href="/#" className="text-warning"><i className="material-icons mr-8pt">star</i></a>
                        <a href className="text-70"><span className="js-lists-values-employer-name">Frontted</span></a>
                      </div>
                    </td>
                    <td className="text-center js-lists-values-projects small">44</td>
                    <td>
                      <a href className="chip chip-outline-secondary">Admin</a>
                    </td>
                    <td className="text-50 js-lists-values-activity small">3 weeks ago</td>
                    <td className="js-lists-values-earnings small">$22,402</td>
                    <td className="text-right pl-0">
                      <a href className="text-50"><i className="material-icons">more_vert</i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="card-footer p-8pt">
              <ul className="pagination justify-content-start pagination-xsm m-0">
                <li className="page-item disabled">
                  <a className="page-link" href="/#" aria-label="Previous">
                    <span aria-hidden="true" className="material-icons">chevron_left</span>
                    <span>Prev</span>
                  </a>
                </li>
                <li className="page-item dropdown">
                  <a className="page-link dropdown-toggle" data-toggle="dropdown" href="/#" aria-label="Page">
                    <span>1</span>
                  </a>
                  <div className="dropdown-menu">
                    <a href className="dropdown-item active">1</a>
                    <a href className="dropdown-item">2</a>
                    <a href className="dropdown-item">3</a>
                    <a href className="dropdown-item">4</a>
                    <a href className="dropdown-item">5</a>
                  </div>
                </li>
                <li className="page-item">
                  <a className="page-link" href="/#" aria-label="Next">
                    <span>Next</span>
                    <span aria-hidden="true" className="material-icons">chevron_right</span>
                  </a>
                </li>
              </ul>
            </div>
            {/* <div className="card-body bordet-top text-right">
  15 <span className="text-50">of 1,430</span> <a href="/#" className="text-50"><i className="material-icons ml-1">arrow_forward</i></a>
</div> */}
          </div>
          <div className="row card-group-row mb-lg-8pt">
            <div className="col-lg card-group-row__col">
              <div className="card card-group-row__card">
                <div className="card-header d-flex align-items-center">
                  <strong className="flex">Checklist</strong>
                  <div><a href="/#" data-target="#todo" className="js-toggle-check-all">Mark All as Completed</a></div>
                </div>
                <div className="progress rounded-0" style={{ height: 4 }}>
                  <div className="progress-bar bg-primary" role="progressbar" style={{ width: '40%' }} aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} />
                </div>
                <div className="card-body">
                  <ul className="list-unstyled list-todo" id="todo">
                    <li>
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="customCheck1" />
                        <label className="custom-control-label" htmlFor="customCheck1">Wireframe the CRM application pages</label>
                      </div>
                    </li>
                    <li>
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="customCheck2" />
                        <label className="custom-control-label" htmlFor="customCheck2">Design a new page in Sketch</label>
                      </div>
                    </li>
                    <li>
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" defaultChecked id="customCheck3" />
                        <label className="custom-control-label" htmlFor="customCheck3">Quote the custom work</label>
                      </div>
                    </li>
                    <li>
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" defaultChecked id="customCheck4" />
                        <label className="custom-control-label" htmlFor="customCheck4">Interview John for Full-Stack Developer</label>
                      </div>
                    </li>
                    <li>
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" defaultChecked id="customCheck5" />
                        <label className="custom-control-label" htmlFor="customCheck5">Research the success of CRM</label>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="card-footer p-8pt">
                  <ul className="pagination justify-content-start pagination-xsm m-0">
                    <li className="page-item disabled">
                      <a className="page-link" href="/#" aria-label="Previous">
                        <span aria-hidden="true" className="material-icons">chevron_left</span>
                        <span>Prev</span>
                      </a>
                    </li>
                    <li className="page-item dropdown">
                      <a className="page-link dropdown-toggle" data-toggle="dropdown" href="/#" aria-label="Page">
                        <span>1</span>
                      </a>
                      <div className="dropdown-menu">
                        <a href className="dropdown-item active">1</a>
                        <a href className="dropdown-item">2</a>
                        <a href className="dropdown-item">3</a>
                        <a href className="dropdown-item">4</a>
                        <a href className="dropdown-item">5</a>
                      </div>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="/#" aria-label="Next">
                        <span>Next</span>
                        <span aria-hidden="true" className="material-icons">chevron_right</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg card-group-row__col">
              <div className="card card-group-row__card">
                <div className="card-header d-flex align-items-center">
                  <strong className="flex">Team Skills</strong>
                  <a href="/#"><i className="material-icons text-50">more_horiz</i></a>
                </div>
                <div className="card-body d-flex flex-column align-items-center justify-content-center">
                  <ul className="list-unstyled list-skills w-100">
                    <li className="mb-8pt">
                      <div className="text-50 border-right"><small>HTML</small></div>
                      <div className="flex">
                        <div className="progress" style={{ height: 4 }}>
                          <div className="progress-bar bg-primary" role="progressbar" style={{ width: '61%' }} aria-valuenow={61} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                      </div>
                      <div className="text-70"><small>61%</small></div>
                    </li>
                    <li className="mb-8pt">
                      <div className="text-50 border-right"><small>CSS</small></div>
                      <div className="flex">
                        <div className="progress" style={{ height: 4 }}>
                          <div className="progress-bar bg-accent" role="progressbar" style={{ width: '39%' }} aria-valuenow={39} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                      </div>
                      <div className="text-70"><small>39%</small></div>
                    </li>
                    <li className="mb-8pt">
                      <div className="text-50 border-right"><small>JavaScript</small></div>
                      <div className="flex">
                        <div className="progress" style={{ height: 4 }}>
                          <div className="progress-bar bg-warning" role="progressbar" style={{ width: '76%' }} aria-valuenow={76} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                      </div>
                      <div className="text-70"><small>76%</small></div>
                    </li>
                    <li className="mb-8pt">
                      <div className="text-50 border-right"><small>Rails</small></div>
                      <div className="flex">
                        <div className="progress" style={{ height: 4 }}>
                          <div className="progress-bar bg-black-50" role="progressbar" style={{ width: '28%' }} aria-valuenow={28} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                      </div>
                      <div className="text-70"><small>28%</small></div>
                    </li>
                    <li className="mb-8pt">
                      <div className="text-50 border-right"><small>Vue.js</small></div>
                      <div className="flex">
                        <div className="progress" style={{ height: 4 }}>
                          <div className="progress-bar bg-black-20" role="progressbar" style={{ width: '50%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                      </div>
                      <div className="text-70"><small>50%</small></div>
                    </li>
                    <li className="mb-0">
                      <div className="text-50 border-right"><small>Laravel</small></div>
                      <div className="flex">
                        <div className="progress" style={{ height: 4 }}>
                          <div className="progress-bar bg-black-20" role="progressbar" style={{ width: '60%' }} aria-valuenow={60} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                      </div>
                      <div className="text-70"><small>60%</small></div>
                    </li>
                  </ul>
                </div>
                <div className="card-footer p-8pt">
                  <ul className="pagination justify-content-start pagination-xsm m-0">
                    <li className="page-item disabled">
                      <a className="page-link" href="/#" aria-label="Previous">
                        <span aria-hidden="true" className="material-icons">chevron_left</span>
                        <span>Prev</span>
                      </a>
                    </li>
                    <li className="page-item dropdown">
                      <a className="page-link dropdown-toggle" data-toggle="dropdown" href="/#" aria-label="Page">
                        <span>1</span>
                      </a>
                      <div className="dropdown-menu">
                        <a href className="dropdown-item active">1</a>
                        <a href className="dropdown-item">2</a>
                        <a href className="dropdown-item">3</a>
                        <a href className="dropdown-item">4</a>
                        <a href className="dropdown-item">5</a>
                      </div>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="/#" aria-label="Next">
                        <span>Next</span>
                        <span aria-hidden="true" className="material-icons">chevron_right</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="page-separator">
            <div className="page-separator__text">Discussions</div>
          </div>
          <div className="card mb-0">
            <div className="card-header">
              <div className="row align-items-center" style={{ whiteSpace: 'nowrap' }}>
                <div className="col-lg-auto">
                  <form className="search-form search-form--dark d-lg-inline-flex mb-8pt mb-lg-0" action="discussions.html">
                    <input type="text" className="form-control w-lg-auto" placeholder="Search discussions" />
                    <button className="btn" type="submit" ><i className="material-icons">search</i></button>
                  </form>
                </div>
                <div className="col-lg d-flex flex-wrap align-items-center">
                  <div className="ml-lg-auto dropdown">
                    <a href="/#" className="btn btn-link dropdown-toggle text-70" data-toggle="dropdown">My Posts</a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a href className="dropdown-item active">My Posts</a>
                      <a href className="dropdown-item">All Posts</a>
                    </div>
                  </div>
                  <a href="discussions-ask.html" className="btn btn-outline-secondary">Ask a question</a>
                </div>
              </div>
            </div>
            <div className="list-group list-group-flush">
              <div className="list-group-item p-3">
                <div className="row align-items-start">
                  <div className="col-md-3 mb-8pt mb-md-0">
                    <div className="media align-items-center">
                      <div className="media-left mr-8pt">
                        <a href className="avatar avatar-32pt"><img src="assets/images/people/110/guy-1.jpg" alt="avatar" className="avatar-img rounded-circle" /></a>
                      </div>
                      <div className="d-flex flex-column media-body media-middle">
                        <a href className="text-body"><strong>Laza Bogdan</strong></a>
                        <small className="text-muted">2 days ago</small>
                      </div>
                    </div>
                  </div>
                  <div className="col mb-8pt mb-md-0">
                    <p className="mb-8pt"><a href="discussion.html" className="text-body"><strong>Using Angular HttpClientModule instead of HttpModule</strong></a></p>
                    <a href="discussion.html" className="chip chip-outline-secondary">Angular fundamentals</a>
                  </div>
                  <div className="col-auto d-flex flex-column align-items-center justify-content-center">
                    <h5 className="m-0">1</h5>
                    <p className="lh-1 mb-0"><small className="text-70">answers</small></p>
                  </div>
                </div>
              </div>
              <div className="list-group-item p-3">
                <div className="row align-items-start">
                  <div className="col-md-3 mb-8pt mb-md-0">
                    <div className="media align-items-center">
                      <div className="media-left mr-8pt">
                        <a href className="avatar avatar-32pt"><img src="assets/images/people/110/guy-2.jpg" alt="avatar" className="avatar-img rounded-circle" /></a>
                      </div>
                      <div className="d-flex flex-column media-body media-middle">
                        <a href className="text-body"><strong>Adam Curtis</strong></a>
                        <small className="text-muted">3 days ago</small>
                      </div>
                    </div>
                  </div>
                  <div className="col mb-8pt mb-md-0">
                    <p className="mb-0"><a href="discussion.html" className="text-body"><strong>Why am I getting an error when trying to install angular/http@2.4.2</strong></a></p>
                  </div>
                  <div className="col-auto d-flex flex-column align-items-center justify-content-center">
                    <h5 className="m-0">1</h5>
                    <p className="lh-1 mb-0"><small className="text-70">answers</small></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer p-8pt">
              <ul className="pagination justify-content-start pagination-xsm m-0">
                <li className="page-item disabled">
                  <a className="page-link" href="/#" aria-label="Previous">
                    <span aria-hidden="true" className="material-icons">chevron_left</span>
                    <span>Prev</span>
                  </a>
                </li>
                <li className="page-item dropdown">
                  <a className="page-link dropdown-toggle" data-toggle="dropdown" href="/#" aria-label="Page">
                    <span>1</span>
                  </a>
                  <div className="dropdown-menu">
                    <a href className="dropdown-item active">1</a>
                    <a href className="dropdown-item">2</a>
                    <a href className="dropdown-item">3</a>
                    <a href className="dropdown-item">4</a>
                    <a href className="dropdown-item">5</a>
                  </div>
                </li>
                <li className="page-item">
                  <a className="page-link" href="/#" aria-label="Next">
                    <span>Next</span>
                    <span aria-hidden="true" className="material-icons">chevron_right</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}

export default Dashboard;
