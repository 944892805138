import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

export default function CardLineGraph(props) {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const labels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const data = {
        labels: labels,
        datasets: [{
            // label: '',
            data: props.data,
            fill: false,
            borderColor: props.lineColor ? props.lineColor : '#2196f3',
            tension: 0.4,
            // pointRadius: 0,
            pointStyle: 'rect',
        }]
    };

    const options = {
        elements:{
            point:{
                borderWidth: 0,
                radius: 10,
                backgroundColor: 'rgba(0,0,0,0)'
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    display: false
                }
            }
        }

    };
    return (
        <>
            <Line options={options} data={data} />

        </>
    )
}