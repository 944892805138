import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

export default function Dchart(props) {

    ChartJS.register(ArcElement, Tooltip, Legend);

    const data = {
        labels: ['United States', 'United Kingdom', 'Germany'],
        datasets: [
            {
                label: '',
                data: props.data,
                backgroundColor: [
                    '#00bcc2',
                    '#2196f3',
                    '#e3ebf6',
                ],
                borderColor: [
                    '#00bcc2',
                    '#2196f3',
                    '#e3ebf6',
                ],
                hoverOffset: 10,
                borderWidth: 0,
            },
        ],
        
    };

    const options = {
        cutout: 62,
        plugins: {
            legend: {
               position: 'right'
            }
          
         }
      };


    return (
        <>
            <Doughnut data={data} 
            options={options}/>
        </>
    )
}
