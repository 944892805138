import React, {useEffect} from 'react'
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world-merc.js";
import "jsvectormap/dist/css/jsvectormap.css";

export default function VectorMap(props) {


  useEffect(() => {
      document.getElementById('map').innerHTML=''

    const map = new jsVectorMap({
      markersSelectable: true,
      regionsSelectable: true,
      regionsSelectableOne: true,
      regionStyle: {
        hover: { fill: '#7dbcf3' },
        selected: { fill: '#63b4f6' },
      },
      markers: [
        { name: 'Egypt', coords: [26.8206, 30.8025] },
        { name: 'United Kingdom', coords: [55.3781, -3.4360] },
        { name: 'China', coords: [35.4326, 99.1332],
          // Add style for this particular marker
          // Keep in mind `style` object is merged with `markerStyle.initial`
          // style: { fill: 'red' }
        }
      ],
      labels: {
        markers: {
          render(marker, index) {
            return marker.name || marker.labelName || 'NA'
          }
        }
      },
      focusOn: {
        region: props.region ? props.region : 'CN',
      },
      selector: '#map',
      map: 'world_merc',
    })

  },[props])

  return (
    <>
      <div id='map' style={{height: '400px'}} ></div>
    </>
  )
}
